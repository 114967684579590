<template>
    <main>
        <section class="hero-wrapper" ref="herowrapper">
            <div class="paralax-wrapper">
                <div class="paralax-bg" ref="hero2">
                    <picture>
                        <source srcset="/img/hero-layer-2-3000.jpg" media="(min-width: 1200px)" />
                        <source srcset="/img/hero-layer-2.jpg" media="(min-width: 800px)" />
                        <img src="/img/hero-layer-2-mobile.jpg" />
                    </picture>
                </div>
                <div class="paralax-top" ref="hero1">
                    <picture>
                        <source srcset="/img/hero-layer-1-3000.png" media="(min-width: 1200px)" />
                        <source srcset="/img/hero-layer-1.png" media="(min-width: 800px)" />
                        <img src="/img/hero-layer-1-mobile.png" />
                    </picture>
                </div>
            </div>
            <b-container v-if="homeData && homeData.fields">
                <b-row>
                    <b-col md='10' lg="9" xl="8" class="hero-text">
                        <div ref="herocontent">
                            <h1 class="hero-title">{{ homeData.fields.coverTitle }}</h1>
                            <p class="hero-subtitle">{{ homeData.fields.coverSubtitle }}</p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div class="clouds-wrapper">
                <b-img src="/img/home-hero-cloud-1.png" fluid alt="" class="cloud-1" ref="cloud1"></b-img>
                <b-img src="/img/home-hero-cloud-2.png" fluid alt="" class="cloud-2" ref="cloud2"></b-img>
            </div>
        </section>

        <section class="hero-subtext" v-if="homeData && homeData.fields">
            <b-container>
                <b-row>
                    <b-col md="9">
                        <p>{{ homeData.fields.description }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="guidance" ref="guidance">
            <b-container class="bv-example-row bv-example-row-flex-cols">
                <b-row>
                    <b-col md="6">
                        <h2 class="guidance-title">Tools and guidance</h2>
                    </b-col>
                </b-row>
                <b-row  align-h="between">
                    <b-col md="7">
                        <p class="sub-title">Walk-through of the seven stages of the Readiness and Preparatory Support Programme cycle with the list of the readiness-related guidance, useful documents, and templates relevant to each stage.</p>
                    </b-col>
                    <b-col md="3" xl="2" class="learn-more">
                        <router-link :to="{name: 'tools'}" class="more">
                            Learn more
                            <span class="svg-wrapper">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="0,0 7,6 0,12" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </router-link>
                    </b-col>
                </b-row>
                <div class="tools">
                    <router-link :to="{name:'tools', params:{'stage': tool.fields.stage}}" class="tools-card" v-for="tool in tools" :key="tool.sys.id">
                        <div class="tag">Stage {{ tool.fields.stage }}</div>
                        <p class="card-title">{{ tool.fields.title }}</p>
                    </router-link>
                </div>
            </b-container>
        </section>

        <section class="results">
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="results-title">Results</h2>
                    </b-col>
                </b-row>
                <b-row  align-h="between" align-v="center" class="mb-5">
                    <b-col md="6">
                        <p class="sub-title">Visualised project results in dashboards and project databases supported with the search function in the Open Data Library platform.</p>
                    </b-col>
                    <b-col md="6" xl="4" class="learn-more">
                        <div class="arrows d-inline-flex">
                            <a class="swiper-prev" :class="resultsPrevButtonDisabled ? 'disable' : ''" :disabled="resultsPrevButtonDisabled" @click="resultsPrev"></a>
                            <a class="swiper-next" :class="resultsNextButtonDisabled ? 'disable' : ''" :disabled="resultsNextButtonDisabled" @click="resultsNext"></a>
                        </div>
                        <router-link :to="{name: 'results'}" class="more">
                            Learn more
                            <span class="svg-wrapper">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="0,0 7,6 0,12" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </router-link>
                    </b-col>
                </b-row>
                <swiper
                    slides-per-view="auto"
                    :slide-per-group="1"
                    :space-between="spaceBetween"
                    :allow-slide-next="!resultsNextButtonDisabled"
                    @swiper="onSwiperResults"
                    @slideChange="onSwiperResultsSlideChange"
                    
                >
                    <swiper-slide class="swiper-slide1" v-for="(result, idx) in results" :key="idx">
                        <b-card class="data-library-card">
                            <div class="card-top">
                                <div class="icon-wrapper"><img :src="result.fields.icon.fields.file.url"/></div>
                                <p class="card-title">{{ result.fields.title }}</p>
                            </div>
                            <div class="card-bottom">
                                <div class="img-wrapper"><img src="/img/open-data-library.png"/></div>
                                <div class="link">
                                    <a :href="result.fields.linkUrl" target="_blank">{{ result.fields.linkText }}</a>
                                    <span>{{ result.fields.description }}</span>
                                </div>
                            </div>
                        </b-card>
                    </swiper-slide>
                </swiper>
            </b-container>
        </section>

        <section class="success-stories" v-if="stories">
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="success-stories-title">Success stories</h2>
                    </b-col>
                </b-row>
                <b-row  align-h="between">
                    <b-col md="5">
                        <p class="sub-title">Success stories of the GCF partners (countries and Accredited Entities) empowered to take climate actions with the GCF support.</p>
                    </b-col>
                    <b-col md="3" lg="2" class="learn-more">
                        <router-link :to="{name: 'stories'}" class="more">
                            See more
                            <span class="svg-wrapper">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="0,0 7,6 0,12" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" v-for="(story, storyIdx) in stories" :key="storyIdx">
                        <div>
                            <b-card no-body class="overflow-hidden" >
                                <b-row no-gutters>
                                    <b-col cols="8">
                                        <div class="card-body">
                                            <div class="top">
                                                <span class="card-location">
                                                    <img :src="'/img/flags/'+story.fields.country.fields.code+'.svg'" width="24" height="18" alt=""/>{{ story.fields.country.fields.title }}
                                                </span>
                                                <p class="card-title"><router-link :to="{name: 'success-story', params: {slug: story.fields.slug, id: story.sys.id}, props: {id: story.sys.id}}" :title="story.fields.title">{{ story.fields.title.length > 85 ? (story.fields.title.substring(0, 85) + '...') : story.fields.title }}</router-link></p>
                                                <span class="time">{{ story.fields.readTime }}</span>
                                            </div>
                                            <router-link :to="{name: 'success-story', params: {slug: story.fields.slug, id: story.sys.id}, props: {id: story.sys.id}}" class="card-link">Read the story</router-link>
                                        </div>
                                    </b-col>
                                    <b-col cols="4">
                                        <div class="img-wrapper" v-if="story.fields.mainCover.sys.id">
                                            <b-card-img :src="getImageSourceString(story.fields.mainCover.fields.file.url)" width="212" height="270" alt=""></b-card-img>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="storiesKeywords.length > 0">
                    <b-col class="topics">
                        <div class="browse">Browse by topic:</div>
                        <router-link
                            v-for="(keyword, kidx) in storiesKeywords"
                            :key="kidx"
                            :to="{ name: 'stories', params: { key: keyword.id } }"
                            class="more"
                        >
                            {{ keyword.title }}<span class="number-wrapper">{{ keyword.count }}</span>
                        </router-link>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="policy" v-if="researches">
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="policy-title">Policy, research, and evaluation</h2>
                    </b-col>
                </b-row>
                <b-row  align-h="between" align-v="center" class="mb-5">
                    <b-col md="9">
                        <p class="sub-title">GCF board decisions and documents relevant to the Readiness and Preparatory Support Programme and the synopses of the IEU studies with the GCF Secretariat's commentary on recent updates to act upon the recommendations.</p>
                    </b-col>
                    <b-col md="3" class="learn-more">
                        <router-link :to="{name: 'policy'}" class="more">
                            See more
                            <span class="svg-wrapper">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="0,0 7,6 0,12" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row class="wrapper">
                    <b-col md="6" class="mb-4" v-for="(res, residx) in researches" :key="residx">
                        <b-card class="policy-card">
                            <div class="img-wrapper"><img v-if="res.fields && res.fields.cover" :src="res.fields.cover.fields.file.url" /></div>
                            <div class="content">
                                <div class="top">
                                    <router-link :to="{ name: 'single-research', params: {slug: res.fields.slug, id: res.sys.id}, props: {id: res.sys.id} }" class="card-title">{{ res.fields.title }}</router-link>
                                    <span class="template" v-if="res.fields.type"><img src="/img/icons/template-icon.svg" />{{ res.fields.type }}</span>
                                </div>
                                <div class="bottom">
                                    <div class="card-links">
                                        <span v-if="res.fields.fileType" class="pdf">{{ res.fields.fileType }}</span>
                                        <span v-if="res.fields.fileSize" class="size">{{ res.fields.fileSize }}</span>
                                        <span v-if="res.fields.language" class="language"><img
                                                src="/img/icons/language-icon.svg" />{{ res.fields.language }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </main>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper.scss'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { createClient } from '@/plugins/contentful.js';
const client = createClient();
// import { ContentfulLivePreview } from '@contentful/live-preview';
// ContentfulLivePreview.init({ locale: 'en-US'});

export default {
    name: 'HomeView',
    props: {
        gs: Boolean,
    },
    metaInfo: {
        titleTemplate: '%s - Home',
        meta: [
            {
                property: 'og:title',
                content: 'Readiness knowledge Bank - Home',
                vmid: 'og:title',
            },
            {
                property: 'og:description',
                content: '',
                vmid: 'og:description',
            },
            {
                property: 'og:url',
                content: '',
                vmid: 'og:url',
            },
            {
                property: 'og:image',
                content: '',
                vmid: 'og:image',
            },
            {
                property: 'twitter:title',
                content: 'Readiness Knowledge Bank - Home',
                vmid: 'twitter:title',
            },
            {
                property: 'twitter:description',
                content: '',
                vmid: 'twitter:description',
            },
            {
                property: 'twitter:url',
                content: '',
                vmid: 'twitter:url',
            },
            {
                property: 'twitter:image',
                content: '',
                vmid: 'twitter:image',
            },
            {
                property: 'dcterms:title',
                content: 'Readiness Knowledge Bank - Home',
                vmid: 'dcterms:title',
            },
            {
                property: 'dcterms:description',
                content: '',
                vmid: 'dcterms:description',
            },
            {
                property: 'dcterms:identifier',
                content: '',
                vmid: 'dcterms:identifier',
            },
        ],
    },
    data() {
        return {
            spaceBetween: 40,
            resultsCarousel: null,
            resultsSwiperCurrent: 0,
            resultsSwiperVisibleSlides: 3,
            //
            policyCarousel: null,
            policySwiperCurrent: 0,
            policySwiperVisibleSlides: 3,
            //
            herotext: null,
            heroimgbg: null,
            heroimgtop: null,
            herocloud1: null,
            herocloud2: null,
            gs2: null,
            //
            tools: [],
            resources: [],
            homeData: [],
            results: [],
            stories: [],
            allStories: [],
            researches: [],
            keywords: [],
            storiesKeywords: [],
            storiesImages: {},
            // meta
            pageLocation: '',
            tags: [],
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    async created() {
        this.tools = await this.getTools();
        this.homeData = await this.getData();
        this.results = await this.getResults();
        this.stories = await this.getStories();
        this.researches = await this.getResearches();
        // this.keywords = await this.getStoriesKeywords();
        // this.fillStoriesKeywords(this.keywords);
        this.allStories = await this.getAllStories();
        this.$nextTick(() => {
            this.resultsCarousel.update();
        })
        if (this.stories) {
            this.stories.forEach(story => {
                if (story.fields.mainCover) {
                    this.storiesImages[story.fields.mainCover.sys.id] = '';
                }
            });
            if (Object.keys(this.storiesImages).length) {
                for (const id in this.storiesImages) {
                    const imageSrc = client.getAsset(id)
                        .then((asset) => {
                            return `${asset.fields.file.url}?fit=crop&w=565&h=720`;
                        })
                        .catch(console.error);
                    const getSrc = async () => {
                        const a = await imageSrc;
                        this.storiesImages[id] = a;
                        this.storiesImages.__ob__.dep.notify();
                    }
                    getSrc();
                }
            }
        }
        this.tags = await this.getTags();
        if (this.tags.length > 0) {
            this.storiesKeywords = this.fillKeywords(this.allStories);
        }
    },
    methods: {
        getImageSourceString(src) {
            return `${src}?fit=crop&w=565&h=720`;
        },
        onSwiperResults(swiper) {
            this.resultsCarousel = swiper;
        },
        resultsPrev() {
            this.resultsCarousel.slidePrev();
        },
        resultsNext() {
            this.resultsCarousel.slideNext();
        },
        onSwiperResultsSlideChange(s) {
            this.resultsSwiperCurrent = s.activeIndex;
        },
        //
        onSwiperPolicy(swiper) {
            this.policyCarousel = swiper;
        },
        policyPrev() {
            this.policyCarousel.slidePrev();
        },
        policyNext() {
            this.policyCarousel.slideNext();
        },
        onSwiperPolicySlideChange(s) {
            this.policySwiperCurrent = s.activeIndex;
        },
        //
        getTools: async () => {
            return Promise.all([
                client.getEntries({
                    content_type: 'tools',
                    order: 'fields.stage',
                }),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                    .catch(console.error);
        },
        getData: async () => {
            return Promise.all([
                client.getEntry('3lb1sM5udyaSKadtEEiivL'),
            ])
                .then(([entry]) => {
                    return JSON.parse(JSON.stringify(entry));
                })
                    .catch(console.error);
        },
        getResults: async () => {
            return Promise.all([
                client.getEntries({
                    content_type: 'libraryResult',
                    // order: 'fields.stage',
                    order: 'sys.createdAt',
                }),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                    .catch(console.error);
        },
        getStories: async () => {
            return Promise.all([
                client.getEntries({
                    content_type: 'successStory',
                    'fields.storyType': 'Country',
                    order: '-sys.createdAt',
                    limit: 2
                }),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                    .catch(console.error);
        },
        getAllStories: async () => {
            return Promise.all([
                client.getEntries({
                    content_type: 'successStory',
                    'fields.storyType': 'Country',
                    select: 'sys.id,metadata',
                }),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                .catch(console.error);
        },
        getResearches: async () => {
            return Promise.all([
                client.getEntries({
                    content_type: 'research',
                    order: 'sys.createdAt',
                    limit: 4
                }),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                    .catch(console.error);
        },
        getTags: async () => {
            return Promise.all([
                client.getTags(),
            ])
                .then(([entries]) => {
                    return JSON.parse(JSON.stringify(entries.items));
                })
                .catch(console.error);
        },
        // getStoriesKeywords: async () => {
        //     return Promise.all([
        //         client.getEntries({
        //             content_type: 'successStory',
        //             'fields.storyType': 'Country',
        //             select: 'sys.id,fields.keywords',
        //             order: 'sys.createdAt',
        //         }),
        //     ])
        //         .then(([entries]) => {
        //             return JSON.parse(JSON.stringify(entries.items));
        //         })
        //             .catch(console.error);
        // },
        getAsset: async (id) => {
            return Promise.all([
                client.getAsset(id),
            ])
                .then(([entry]) => {
                    return JSON.parse(JSON.stringify(entry));
                })
                    .catch(console.error);
        },
        // fillStoriesKeywords: function(entries) {
        //     if (entries && entries.length) {
        //         entries.forEach(story => {
        //             if (story.fields.keywords && story.fields.keywords.length > 0) {
        //                 story.fields.keywords.forEach(kw => {
        //                     this.storiesKeywords.push({id: kw.sys.id, title: kw.fields.title});
        //                 });
        //             }
        //         });
        //         var obj = {}
        //         for(let { title, id } of this.storiesKeywords)
        //             obj[id] = {
        //                 id,
        //                 title,
        //                 count: obj[id] ? obj[id].count + 1 : 1
        //             }
        //         this.storiesKeywords = Object.values(obj)
        //     }
        // },
        fillKeywords: function(entries) {
            let keys = [];
            if (entries && entries.length) {
                entries.forEach(story => {
                    if (story.metadata.tags && story.metadata.tags.length > 0) {
                        story.metadata.tags.forEach(kw => {
                            const found = this.tags.find(el => el.sys.id == kw.sys.id);
                            if (found) keys.push({id: found.sys.id, title: found.name.split(": ")[1]});
                        });
                    }
                });
                var obj = {}
                for(let { title, id } of keys)
                    obj[id] = {
                        id,
                        title,
                        count: obj[id] ? obj[id].count + 1 : 1
                    }
                keys = Object.values(obj);
                keys.sort(function(a, b) {
                    if (a.title < b.title) return -1;
                    if (a.title > b.title) return 1;
                    return 0;
                });
            }
            return keys;
        },
    },
    computed: {
        resultsPrevButtonDisabled() {
            return this.resultsSwiperCurrent == 0;// this.resultsCarousel.isBeginning;
        },
        resultsNextButtonDisabled() {
            if (!this.resultsCarousel) return false;
            return this.resultsSwiperCurrent >= this.resultsCarousel.slides.length - this.resultsSwiperVisibleSlides;
        },
        policyPrevButtonDisabled() {
            return this.policySwiperCurrent == 0;
        },
        policyNextButtonDisabled() {
            if (!this.policyCarousel) return false;
            return this.policySwiperCurrent >= this.policyCarousel.slides.length - this.policySwiperVisibleSlides;
        },
    },
    mounted() {
        let self = this;
        this.herotext = gsap.to(this.$refs.herocontent, {
            duration: 1,
            y: '-=120',
            scrollTrigger: {
                trigger: this.$refs.herowrapper,
                start: 'top top',
                scrub: true,
                // markers: true,
            }
        });
        this.heroimgbg = gsap.to(this.$refs.hero1, {
            y: '-=80',
            scrollTrigger: {
                trigger: this.$refs.herowrapper,
                start: 'top top',
                scrub: true,
            }
        });
        this.heroimgtop = gsap.to(this.$refs.hero2, {
            y: '-=40',
            scrollTrigger: {
                trigger: this.$refs.herowrapper,
                start: 'top top',
                scrub: true,
            }
        });
        this.herocloud1 = gsap.to(this.$refs.cloud1, {
            y: '-=180',
            alpha: 0.4,
            scrollTrigger: {
                trigger: this.$refs.herowrapper,
                start: 'top top',
                scrub: true,
            }
        });
        this.herocloud2 = gsap.to(this.$refs.cloud2, {
            y: '-=190',
            alpha: 0.4,
            scrollTrigger: {
                trigger: this.$refs.herowrapper,
                start: 'top top',
                scrub: true,
            }
        });
        this.gs2 = gsap.from(this.$refs.guidance.querySelectorAll('.tools-card'), {
            duration: 0.3,
            y: '+=33',
            autoAlpha: 0,
            ease: 'sine.out',
            stagger: 0.05,
            scrollTrigger: {
                trigger: this.$refs.guidance,
                start: 'top center',
                toggleActions: 'play pause resume reverse',
                // markers: true,
            }
        });
        // this.
        // if (this.gs) {
            // console.log(this.herotext.scrollTrigger)
            setTimeout(function(){
                self.herotext.scrollTrigger.refresh();
                self.heroimgbg.scrollTrigger.refresh();
                self.heroimgtop.scrollTrigger.refresh();
                self.herocloud1.scrollTrigger.refresh();
                self.herocloud2.scrollTrigger.refresh();
                self.gs2.scrollTrigger.refresh();
            }, 40);
        // }
        this.$gtm.trackView('HomePage', '/');
        this.pageLocation = window.location;
    },
    beforeDestroy() {
        this.herotext.kill();
        this.heroimgbg.kill();
        this.heroimgtop.kill();
        this.herocloud1.kill();
        this.herocloud2.kill();
        this.gs2.kill();
    },
}
</script>

<style scoped lang="scss">
@import '../sass/components/mixins';
.hero-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    // min-height: 630px;
        &::after {
            content: "";
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0) 96%);
            width: 100%;
            position: absolute;
            top: 0;
            height: 100%;
            /* z-index: 111; */
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    .paralax-wrapper {
        position: relative;
        @include mobile {
            height: 60vh;
            min-height: 550px;
        }
        @include tablet {
            height: 45vh;
            min-height: 560px;
        }
        @include large {
            height: 80vh;
            min-height: 600px;
        }
        @include desktop {
            height: 90vh;
            min-height: 690px;
        }
        // .img-wrapper {
        //     @include mobile {
        //         img {
        //             max-width: none;
        //             height: 100%;
        //         }
        //     }
        // }
        .paralax-bg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            img {
                width: 100%;
            }
        }
        .paralax-top {
            position: absolute;
            top: 55%;
            img {
                width: 100%;
            }
        }
    }
}

.hero-text {
    position: absolute;
    top: 247px;
    // transform: translatey(-50%);
    text-align: left;
    z-index: 2;
    @include mobile {
        top: 50%;
        transform: translatey(-50%);
    }
    @media screen and (min-width: 2000px) {
        padding-right: 20vw;
    }
    .hero-title {
        font-size: 70px;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        margin-bottom: 20px;
        @include mobile {
            font-size: 32px;
        }
        @include tablet {
            font-size: 45px;
        }

    }
    .hero-subtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.96px;
        color: #fff;
        @include mobile {
            font-size: 16px;
        }
    }
}

.clouds-wrapper {
    display: flex;
    align-items: center;
    // top: 55%;
    // transform: translatey(-50%);
    z-index: 111;
    // overflow: hidden;
    img {
        position: absolute;
    }
    .cloud-1 {
        top: 76%;
        left: 0;
        @include mobile {
            top: 65%;
        }
    }
    .cloud-2 {
        top: 75%;
        right: 0;
        @include mobile {
            display: none;
        }
    }
}

.hero-subtext {
    position: relative;
    text-align: start;
    padding-top: 80px;
    padding-bottom: 20px;
    p {
        font-family: 'MuseoSlab';
        font-size: 24px;
        font-weight: 500;
        line-height: 1.7;
        letter-spacing: -0.96px;
        color: #4b9f54;
    }
}

.guidance {
    padding-top: 80px;
    padding-bottom: 50px;
    h2 {
        margin-bottom: 30px;
    }
    .tools {
        // column-count: 7;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 10px;
        @include mobile {
            grid-template-columns: repeat(2 ,1fr);
        }
        @include tablet {
            grid-template-columns: repeat(4 ,2fr);
        }
        .tools-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            min-height: 190px;
            background-color: #e9f6e9;
            border-radius: 12px;
            padding: 15px 10px 15px 15px;
            text-decoration: none;
            transition: box-shadow 0.3s ease;
            &:hover {
                box-shadow: 2px 5px 12px 0 rgba(178, 206, 178, 0.3);
            }
        }
        .tag {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.1;
            letter-spacing: -0.48px;
            color: #fff;
            padding: 3px 8px;
            background-color: #009639;
            border-radius: 22px;
        }
        .card-title {
            font-family: 'MuseoSlab';
            font-weight: bold;
            letter-spacing: -0.64px;
            color: #0d6016;
            
        }
    }
}

.results {
    background-color: #009639;
    padding-top: 60px;
    padding-bottom: 50px;
    overflow: hidden;
    .results-title {
        color: white;
        margin-bottom: 30px;
    }
    .sub-title {
        color: white;
        margin-bottom: 0;
    }
    .learn-more {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        @include mobile {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    .more {
        border: 1px solid transparent;
        box-shadow: none;
        &:hover {
            background-color: transparent;
            border: 1px solid white;
        }
    }
        .swiper-next,
        .swiper-prev {
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            &::after {
                background-color: white;
            }
            &.disable {
                border-top: 2px solid #99c298;
                border-left: 2px solid #99c298;
                cursor: default;
                pointer-events: none;
                &::after {
                    background-color: #99c298;
                }
            }
        }
    .swiper-container-initialized {
        max-width: 100%;
        margin-left: 0;
        @include tablet {
            max-width: 50%;
        }
        @include large {
            max-width: 40%;
        }
        @include desktop {
            max-width: 31%;
        }
    }
    .swiper-slide1 {
        // max-width: 400px;
        // margin-right: 40px !important;
        width: 100% !important;
    }
    .card {
        .card-top {
            margin-bottom: 100px;
            .icon-wrapper {
                min-height: 84px;
                margin-bottom: 20px;
            }
            .card-title {
                font-family: 'MuseoSlab';
                font-size: 27px;
                font-weight: 500;
                line-height: 1.1;
                letter-spacing: -1.08px;
            }
        }
        .card-bottom {
            display: flex;
            .img-wrapper {
                margin-right: 25px;
            }
            .link {
                display: flex;
                flex-direction: column;
                a {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: -0.64px;
                    color: #4b9f54;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: -0.56px;
                    color: #8b8686;
                }
            }
        }
    }
}

.success-stories {
    background-color: #f6f7f8;
    padding-top: 75px;
    padding-bottom: 100px;
    .success-stories-title {
        margin-bottom: 40px
    };
    .card {
        border: 1px solid #e9e4e4;
        border-radius: 12px;
        margin-bottom: 35px;
        transition: box-shadow 0.3s ease;
        .row {
            margin: 0px;
            & > * {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        .img-wrapper {
            // max-height: 230px;
            // display: flex;
            // justify-content: flex-end;
            // .card-img {
            //     width: auto;
            //     border-top-left-radius: 0px;
            //     border-bottom-left-radius: 0px;
            //     transition: transform 0.3s ease;
            // }

            // max-height: 210px;
            height: 270px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .card-img {
                // height: 100%;
                max-height: 100%;
                width: auto;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                transition: transform 0.3s ease;
                @include mobile {
                    width: 100%;
                }
            }
            @include mobile {
                height: 270px;
                width: max-content;
            }
            @include tablet {
                height: 235px;
            }
        }
        &:hover {
            box-shadow: 0 0 15px rgba(10, 10, 10, 0.15);
            .card-img {
                transform: scale(1.1);
            }
        }
    }
    .card-body {
        height: 100%;
        padding: 20px 20px 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .card-location {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.1;
        color: #6f7075;
        margin-bottom: 17px;
        img {
            margin-right: 10px;
        }
    }
    .card-title {
        font-family: 'MuseoSlab';
        font-size: 24px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: -0.96px;
        margin-bottom: 10px;
        a {
            color: inherit;
            text-decoration: none;
            transition: color 0.3s;
            &:hover {
                color: #4b9f54;
            }
        }
    }
    .time {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.1;
        letter-spacing: -0.48px;
        color: #7d89a4;
    }
    .card-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.1;
        letter-spacing: -0.56px;
        color: #4b9f54;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .browse {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.64px;
        color: #73937f;
        margin-bottom: 15px;
        
    }
    .topics {
        .more {
            font-size: 14px;
            font-weight: 500;
            color: #009639;
            margin-right: 10px;
            margin-bottom: 15px;
            &:hover {
                color: #fff;
                .number-wrapper {
                    background-color: #fff;
                    color: #009639;
                }
            }
            .number-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                font-size: 12px;
                font-weight: 600;
                color: #fff;
                
            }
        }
    }
}

.policy {
    background-color: #f6f7f8;
    padding-top: 20px;
    padding-bottom: 130px;
    overflow: hidden;
    .policy-title {
        margin-bottom: 45px;
    }
    // .swiper-next,
    // .swiper-prev {
    //     border-top: 2px solid #4b9f54;
    //     border-left: 2px solid #4b9f54;
    //     &::after {
    //         background-color: #4b9f54;
    //     }
    //     &.disable {
    //         border-top: 2px solid #bfe0bf;
    //         border-left: 2px solid #bfe0bf;
    //         cursor: default;
    //         pointer-events: none;
    //         &::after {
    //             background-color: #bfe0bf;
    //         }
    //     }
    // }
    // .swiper-next {
    //     transform: rotate(135deg);
    // }
    // .swiper-prev {
    //     transform: rotate(-45deg);
    // }
    // .swiper-container-initialized {
    //     max-width: 30%;
    //     margin-left: 0;
    // }
    // .swiper-slide {
    //     // max-width: 400px;
    //     // margin-right: 40px !important;
    //     width: 100% !important;
    // }
    // .card {
    //     border: none;
    //     border-radius: 12px;
    //     box-shadow: 0 12px 30px 0 #e4f0e7;
    //     .card-body {
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         // min-height: 470px;
    //         padding: 0;
    //         max-height: 435px;
    //     }
    //     .body {
    //         min-height: 270px;
    //         padding: 20px;
    //     }
    //     .card-title {
    //         font-family: 'MuseoSlab';
    //         font-size: 27px;
    //         font-weight: bold;
    //         line-height: 1.1;
    //         letter-spacing: -1.08px;
    //         margin-bottom: 20px;
    //     }
    //     .card-text {
    //         font-size: 15px;
    //         font-weight: 500;
    //         line-height: 1.5;
    //         letter-spacing: -0.6px;
    //         color: #829382;
    //     }
    //     .img-wrapper {
    //         display: flex;
    //         align-items: center;
    //         max-height: 200px;
    //         // border-radius: 12px;
    //         border-bottom-left-radius: 12px;
    //         border-bottom-right-radius: 12px;
    //         overflow: hidden;
    //     }
    // }
    .policy-card {
        border: 1px solid #e9e4e4;
        border-radius: 12px;
        transition: box-shadow 0.3s ease;
        &:hover {
            box-shadow: 0 0 15px rgba(10, 10, 10, 0.15);
        }

        @include mobile {
            margin-bottom: 20px;
        }

        .card-body {
            display: flex;
            padding: 12px 20px 13px 11px;
        }

        .img-wrapper {
            max-height: 145px;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            margin-right: 20px;
            max-width: 105px;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }

        .card-title {
            display: block;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.3;
            letter-spacing: -0.8px;
            color: #000;
            text-decoration: none;
            margin-bottom: 0;
            transition: color 0.3s ease;

            &:hover {
                color: #009639;
            }
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .card-links {
                display: flex;
                align-items: center;
            }

            .pdf {
                padding: 4px 6px;
                background-color: #edf0f6;
                border-radius: 3px;
            }

            .size {
                text-transform: uppercase;
            }

            .tags {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .tag {
                font-size: 12px;
                font-weight: 500;
                padding: 3px 7px;
                border-radius: 4px;
                background-color: #f0f8f1;
                color: #37813f;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        span {
            display: inline-flex;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.1;
            letter-spacing: -0.48px;
            color: #7d89a4;
            margin-right: 10px;
            align-items: center;

            img {
                margin-right: 5px;
            }
        }
    }
}

</style>