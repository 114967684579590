<template>
    <div id="app" :class="className">
        <script type="application/javascript">(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type="text/javascript",l.async=!0,l.id=o,l.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdyxhAYEFrfnfhu7TDYcP6pmJ_2Fve0_2B5MnYfiXDL_2ByUF_2Fs.js",c.parentNode.insertBefore(l,c))})(window,document,"script","smcx-sdk");</script>
        <HeaderNav/>
        <router-view :gs="true" />
        <FooterNav/>
  </div>
</template>

<script>
import HeaderNav from './components/HeaderNav.vue'
import FooterNav from './components/FooterNav.vue'

export default {
    name: 'App',
    components: {
        HeaderNav,
        FooterNav,
    },
    data() {
        return {
        }
    },
    computed: {
        className() {
            return this.$route.meta.className
        }
    },
    watch:{
        $route (to, from){
            // console.log(to, from)
        }
    },
    metaInfo: {
        title: 'Readiness Knowledge Bank',
        meta: [
            {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type',
            },
            {
                property: 'og:site_name',
                content: 'Green Climate Fund',
                vmid: 'og:site_name',
            },
            {
                property: 'og:title',
                content: 'Readiness Knowledge Bank',
                vmid: 'og:title',
                // template: chunk => `${chunk} - page`,
            },
            {
                property: 'og:url',
                content: '',
                vmid: 'og:url',
            },
            {
                property: 'og:description',
                content: '',
                vmid: 'og:description',
            },
            {
                property: 'og:image',
                content: '',
                vmid: 'og:image',
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image',
                vmid: 'twitter:card',
            },
            {
                property: 'twitter:creator',
                content: '@theGCF',
                vmid: 'twitter:creator',
            },
            {
                property: 'twitter:title',
                content: 'Readiness Knowledge Bank',
                vmid: 'twitter:title',
            },
            {
                property: 'twitter:url',
                content: '',
                vmid: 'twitter:url',
            },
            {
                property: 'twitter:description',
                content: '',
                vmid: 'twitter:description',
            },
            {
                property: 'twitter:image',
                content: '',
                vmid: 'twitter:image',
            },
            {
                property: 'dcterms:title',
                content: 'Readiness Knowledge Bank',
                vmid: 'dcterms:title',
            },
            {
                property: 'dcterms:creator',
                content: 'Green Climate Fund',
                vmid: 'dcterms:creator',
            },
            {
                property: 'dcterms:publisher',
                content: 'Readiness Knowledge Bank',
                vmid: 'dcterms:publisher',
            },
            {
                property: 'dcterms:description',
                content: '',
                vmid: 'dcterms:description',
            },
            {
                property: 'dcterms:identifier',
                content: '',
                vmid: 'dcterms:identifier',
            },
            {
                property: 'dcterms:date',
                content: '',
                vmid: 'dcterms:date',
            },
            {
                property: 'dcterms:type',
                content: 'Text',
                vmid: 'dcterms:type',
            },
            {
                property: 'dcterms:format',
                content: 'text/html',
                vmid: 'dcterms:format',
            },
        ],
    },
}
</script>

<style lang="scss">
@import './sass/app.scss';
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
</style>

<!-- 
<meta property="og:type" content="website" />
<meta property="og:site_name" content="Green Climate Fund" />
<meta property="og:title" content="PSAA overview" />
<meta property="og:url" content="https://www.greenclimate.fund/projects/psaa" />
<meta property="og:description" content="Overview The Project-specific Assessment Approach (PSAA) Pilot is one of two approaches to GCF accreditation, approved through updates to the Accreditation Framework of the GCF (decision B.31/06). The PSAA is a capacity assessment of an entity to meet GCF accreditation standards to implement one climate project or programme. The proposed project or programme is aligned with developing countries’ priorities and GCF’s strategic objectives."
/>
<meta property="og:updated_time" content="2023-03-31T16:13:33+09:00" />
<meta property="og:image" content="https://www.greenclimate.fund/sites/all/themes/gcf/img/meta.jpg" />
<meta property="og:image:url" content="https://www.greenclimate.fund/sites/all/themes/gcf/img/meta.jpg" />
<meta property="og:image:secure_url" content="https://www.greenclimate.fund/sites/all/themes/gcf/img/meta.jpg" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:creator" content="@theGCF" />
<meta name="twitter:url" content="https://www.greenclimate.fund/projects/psaa" />
<meta name="twitter:title" content="PSAA overview" />
<meta name="twitter:description" content="Overview The Project-specific Assessment Approach (PSAA) Pilot is one of two approaches to GCF accreditation, approved through updates to the Accreditation Framework of the GCF (decision B.31/06)." />
<meta name="twitter:image" content="https://www.greenclimate.fund/sites/all/themes/gcf/img/meta.jpg" />
<meta name="dcterms.title" content="PSAA overview" />
<meta name="dcterms.creator" content="Green Climate Fund" />
<meta name="dcterms.description" content="Overview The Project-specific Assessment Approach (PSAA) Pilot is one of two approaches to GCF accreditation, approved through updates to the Accreditation Framework of the GCF (decision B.31/06). The PSAA is a capacity assessment of an entity to meet GCF accreditation standards to implement one climate project or programme. The proposed project or programme is aligned with developing countries’ priorities and GCF’s strategic objectives."
/>
<meta name="dcterms.publisher" content="Green Climate Fund" />
<meta name="dcterms.date" content="2023-03-31T16:13:33+09:00" />
<meta name="dcterms.type" content="Text" />
<meta name="dcterms.format" content="text/html" />
<meta name="dcterms.identifier" content="https://www.greenclimate.fund/projects/psaa" />
 -->