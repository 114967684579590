import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import ToolsView from '../views/ToolsView.vue'
// import StoriesView from '../views/StoriesView.vue'
// import ResultsView from '../views/ResultsView.vue'
// import PolicyView from '../views/PolicyView.vue'
// import PolicySingleView from '../views/PolicySingleView.vue'
// import StoriesSingleView from '../views/StoriesSingleView.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: { gtm: 'HomePage' },
  },
  { path: '/readiness', redirect: { name: 'home' } },
  {
    path: '/readiness/about-rkb',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { gtm: 'AboutRKB' },
  },
  {
    path: '/readiness/tools/:params?',
    name: 'tools',
    component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsView.vue'),
    meta: { gtm: 'Tools' },
  },
  {
    path: '/readiness/stories',
    name: 'stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/StoriesView.vue'),
    meta: { gtm: 'Stories' },
  },
  // {
  //   path: '/stories-single',
  //   name: 'stories-single',
  //   component: () => import(/* webpackChunkName: "stories" */ '../views/StoriesSingleView.vue'),
  // },
  {
    path: '/readiness/stories/:slug',
    name: 'success-story',
    component: () => import(/* webpackChunkName: "stories" */ '../views/StoriesSingleView.vue'),
    props: true,
    meta: {
      className: 'stories-single',
      gtm: 'SingleStory',
    }
  },
  // {
  //   path: '/stories/colombia',
  //   name: 'colombia-story',
  //   component: () => import(/* webpackChunkName: "stories" */ `../views/stories/ColombiaView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  // {
  //   path: '/stories/jordan',
  //   name: 'jordan-story',
  //   component: () => import(/* webpackChunkName: "stories" */ `../views/stories/JordanView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  // {
  //   path: '/stories/cook-islands',
  //   name: 'cook-islands-story',
  //   component: () => import(/* webpackChunkName: "stories" */ `../views/stories/CookIslandsView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  // {
  //   path: '/stories/liberia',
  //   name: 'liberia-story',
  //   component: () => import(/* webpackChunkName: "stories" */ `../views/stories/LiberiaView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  {
    path: '/readiness/entities/:country?',
    name: 'entity-story',
    component: () => import(/* webpackChunkName: "stories" */ '../views/StoriesSingleView.vue'),
    props: true,
    meta: {
      className: 'stories-single',
      gtm: 'SingleEntity',
    }
  },
  // {
  //   path: '/entities/fondo-accion',
  //   name: 'colombia-entity',
  //   component: () => import(/* webpackChunkName: "entities" */ `../views/entities/ColombiaEntityView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  // {
  //   path: '/entities/gggi',
  //   name: 'gggi-entity',
  //   component: () => import(/* webpackChunkName: "entities" */ `../views/entities/GggiEntityView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  // {
  //   path: '/entities/js-bank',
  //   name: 'js-bank-entity',
  //   component: () => import(/* webpackChunkName: "entities" */ `../views/entities/JsBankEntityView.vue`),
  //   meta: {
  //     className: 'stories-single'
  //   }
  // },
  {
    path: '/readiness/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue'),
    meta: {
      gtm: 'Results',
    }
  },
  {
    path: '/readiness/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/PolicyView.vue'),
    meta: {
      gtm: 'Policy',
    }
  },
  // {
  //   path: '/policy-single',
  //   name: 'policy-single',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/PolicySingleView.vue')
  // },
  {
    path: '/readiness/research/:slug',
    name: 'single-research',
    component: () => import(/* webpackChunkName: "policy" */ '../views/PolicySingleView.vue'),
    props: true,
    meta: {
      gtm: 'SingleResearch',
    }
  },
  // {
  //   path: '/policy/readiness-and-preparatory-support-programme',
  //   name: 'policy-1',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy1View.vue')
  // },
  // {
  //   path: '/policy/country-ownership-approach',
  //   name: 'policy-2',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy2View.vue')
  // },
  // {
  //   path: '/policy/adaptation-portfolio-and-approach-of-the-gcf',
  //   name: 'policy-3',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy3View.vue')
  // },
  // {
  //   path: '/policy/investments-in-ldcs',
  //   name: 'policy-4',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy4View.vue')
  // },
  // {
  //   path: '/policy/investments-in-sids',
  //   name: 'policy-5',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy5View.vue')
  // },
  // {
  //   path: '/policy/approach-to-the-private-sector',
  //   name: 'policy-6',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy6View.vue')
  // },
  // {
  //   path: '/policy/accreditation-function',
  //   name: 'policy-7',
  //   component: () => import(/* webpackChunkName: "policy" */ '../views/policy/Policy7View.vue')
  // },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      gtm: '404',
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  output: {
    path: '/js',
    filename: '[name].js',
    chunkFilename: 'js/[id].[chunkhash].js',
    publicPath: '/',
  },
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return { x: 0, y: 0 }
      }
  }
})

export default router
