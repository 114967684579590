<template>
    <footer>
        <b-container>
            <b-row align-h="between">
                <b-col md="2">
                    <div class="logo-wrapper">
                        <img src="/img/icons/logo-footer.png" alt="" width="124" height="79" class="logo-planet">
                    </div>
                </b-col>
                <b-col md="2" class="text-end-about">
                    <p><a href="https://www.greenclimate.fund/about" class="about">About GCF</a></p>
                    <p><a href="https://www.greenclimate.fund/about/secretariat" class="about">Secretariat</a></p>
                    <p><a href="https://www.greenclimate.fund/terms-and-conditions" class="about">Terms and Conditions</a></p>
                    <p><a href="https://www.greenclimate.fund/privacy-statement" class="about">Privacy Policy</a></p>
                </b-col>
                <b-col md="2"  class="text-end">
                    <!-- <div class="footer-links">
                        <a href="">Sitemap</a>
                    </div>
                    <span class="rights">© 2022. All Rights Reserved.</span> -->
                </b-col>
                <b-col md="3">
                    <div class="contacts">
                        <p>Songdo Business District</p>
                        <p>175 Art center-daero</p>
                        <p>Yeonsu-gu, Incheon 22004</p>
                        <p>Republic of Korea</p>
                        <p><a href="tel:+82324586059" class="tell">+82 32 458 6059 (KST)</a></p>
                    </div>
                    <div class="social">
                        <a href="https://facebook.com/GCFOfficial" class="icon-wrapper"><img src="/img/icons/facebook.svg"/></a>
                        <a href="https://www.instagram.com/greenclimatefund/" class="icon-wrapper"><img src="/img/icons/instagram.svg"/></a>
                        <a href="https://twitter.com/theGCF" class="icon-wrapper"><img src="/img/icons/twitter.svg"/></a>
                        <a href="https://www.linkedin.com/company/green-climate-fund/" class="icon-wrapper"><img src="/img/icons/linkedin.svg"/></a>
                        <a href="https://www.youtube.com/channel/UCh1nzlAPu8WebD8eV7iTpug" class="icon-wrapper"><img src="/img/icons/youtube.svg"/></a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'FooterNav',
}
</script>

<style scoped lang="scss">
@import '../sass/components/mixins';

    footer {
        background-color: #022a17;
        padding-top: 40px;
        padding-bottom: 60px;
        p {
            margin-bottom: 5px;
            &:last-of-type {
                margin-bottom: 1rem;
            }
        }
        .logo-wrapper {
            @include mobile {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
        .contacts,
        .tell,
        .about {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.6;
            letter-spacing: -0.56px;
            color: #fff;
            @include mobile {
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }
        .contacts {
            p {
                margin-bottom: 0;
                &:last-of-type {
                    margin-bottom: 1rem;
                }
            }
            @include mobile {
                flex-direction: column;
                margin-bottom: 20px;
            }
            @include desktop {
                text-align: right;
            }
        }
        .about {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            @include mobile {
                margin-bottom: 20px;
            }
        }
        // .text-end-about {
        //     margin-left: 110px;
        //     @include mobile {
        //         margin-left: 0px;
        //     }
        // }
        .social {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 40px;
            .icon-wrapper {
                display: flex;
            }
            @include mobile {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
        .footer-links {
            margin-bottom: 10px;
            a {
                font-size: 13px;
                font-weight: 300;
                line-height: 1.6;
                letter-spacing: -0.52px;
                color: #7aa18f;
                margin-left: 15px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            @include mobile {
                display: flex;
                justify-content: center;
            }
        }
        .rights {
            font-size: 13px;
            font-weight: 300;
            line-height: 1.6;
            letter-spacing: -0.52px;
            color: #7aa18f;
            @include mobile {
                display: flex;
                justify-content: center;
            }
        }
    }
</style>