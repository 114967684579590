<template>
    <header>
        <b-navbar toggleable="lg" type="dark" ref="navBar" class="navBar">
            <!-- <b-navbar-brand> -->
                <router-link to="/" class="navbar-brand">
                    <div class="logo-wrapper">
                        <img src="/img/icons/logo-gcf-globe.svg" alt="" width="82" height="94" class="logo-planet">
                        <img src="/img/icons/logo-gcf-texts-white.svg" alt="" width="71" height="51" class="logo-text">
                        <!-- <img src="/img/icons/gcf-logo-text-black.png" alt="" width="71" height="51" class="logo-text black"> -->
                    </div>
                    <!-- <img src="/img/icons/gcf-logo-planet.png" alt="" width="36" height="36" class="logo"> -->
                    <div class="readiness">
                        <span>Readiness</span>
                        <span>Knowledge</span>
                        <span>Bank</span>
                    </div>
                </router-link>
            <!-- </b-navbar-brand> -->

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav class="nav-menu">
                <b-navbar-nav class="ml-auto">
                    <li class="nav-item"><router-link :to="{name: 'about'}" class="nav-link">About RKB</router-link></li>
                    <li class="nav-item"><router-link :to="{name: 'tools'}" class="nav-link">Tools and guidance</router-link></li>
                    <li class="nav-item"><router-link :to="{name: 'results'}" class="nav-link">Results</router-link></li>
                    <li class="nav-item"><router-link :to="{name: 'stories'}" class="nav-link">Success stories</router-link></li>
                    <li class="nav-item"><router-link :to="{name: 'policy'}" class="nav-link">Policy, research and evaluation</router-link></li>
                    <!-- <li class="nav-item white"><img src="/img/icons/search-icon.svg"></li>
                    <li class="nav-item black"><img src="/img/icons/search-icon-black.svg"></li>
                    <b-nav-form class="search">
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                    </b-nav-form> -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-link @click="toggleSm" class="toggle-sm"><img src="/img/icons/sm-icon.svg" alt=""><span>We welcome your feedback</span></b-link>
    </header>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'HeaderNav',
    components: {},
    data() {
        return {
            showSmToggler: false,
        }
    },
    mounted() { 
        // const { navBar } = this.$refs
        // const timeline = new TimelineLite() 
        
        // gsap.timeline({ 
        //     scrollTrigger:{
        //         trigger: '.navBar',
        //         start: "top top",
        //         end: 10000,
        //         markers: true,
        //         toggleClass: {
        //             targets: '.navBar',
        //             className: "light"
        //         }
        //     }
        //  }) 

        ScrollTrigger.create({
            trigger: '#app',
            start: "top top-=50",
            end: 10000,
            // markers: true,
            toggleClass: {
                targets: '.navBar',
                className: "light"
            }
        });

        this.showSmToggler = this.showSmButton();
        setTimeout(() => {
            const toggler = document.querySelector(".toggle-sm");
            if (this.showSmToggler) {
                toggler.style.display = "block";
            }
            document.cookie = 'smcx_shown_popup=true';
            // this.setCookie('smcx_shown_popup', true);
        }, 1000);
    },
    methods: {
        toggleSm(e) {
            e.preventDefault();
            const sm = document.querySelector(".smcx-modal");
            if (sm) {
                sm.classList.toggle("smcx-transparent");
                sm.classList.toggle("smcx-hide");
            }
        },
        showSmButton() {
            const smcx = this.getCookie('smcx_453465246_last_shown_at');
            return smcx ? false : true
        },
        getCookie(name) {
            if (typeof window == 'undefined') return null;
            const nameEquals = name + '=';
            const cookieArray = document.cookie.split(';');

            for (let cookie of cookieArray) {
                while (cookie.charAt(0) == ' ') {
                    cookie = cookie.slice(1, cookie.length);
                }

                if (cookie.indexOf(nameEquals) == 0)
                    return decodeURIComponent(
                        cookie.slice(nameEquals.length, cookie.length),
                    );
            }

            return null;
        },
        // getCookie(name) {
        //     if (typeof window == 'undefined') return null;
        //     let matches = document.cookie.match(new RegExp(
        //         "(?:^|; )" + name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1') + "=([^;]*)"
        //     ));
        //     return matches ? decodeURIComponent(matches[1]) : undefined;
        // },
        setCookie(name, value, options = {}) {
            options = {
                path: '/',
                samesite: 'none',
                // add other defaults here if necessary
                ...options
            };
            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString();
            }
            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
            for (let optionKey in options) {
                updatedCookie += "; " + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                    updatedCookie += "=" + optionValue;
                }
            }
            document.cookie = updatedCookie;
        }
    }, 
}
</script>

<style scoped lang="scss">
@import '../sass/components/mixins';

header {
    position: fixed;
    width: 100%;
    z-index: 999;
    .navBar {
        background-color: transparent;
        padding-right: 15px;
        padding-left: 20px;
        transition: background-color 0.3s ease;
        &.light {
            // background-color: #022a17;
            background-color: #fff;
            display: flex;
            align-items: center;
        }
        @include mobile {
            // background-color: #022a17;
            background-color: #fff;
        }
        @include tablet {
            // background-color: #022a17;
            background-color: #fff;
        }
        .navbar-toggler {
            border: none;
            @include mobile {
                filter: brightness(0.4);
            }
            @include tablet {
                filter: brightness(0.4);
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.navbar {
    align-items: flex-start;
}
.light {
    // background-color: rgba(0, 106, 12, 0.399);
    // align-items: center;
    .logo-wrapper {
        display: block;
        .logo-planet {
            width: 36px;
            height: 36px;
            transition: all 0.5s ease;
        }
        .logo-text {
            position: absolute;
            opacity: 0;
            margin-top: 0px;
            transition: all 0s ease;
            // position: absolute;
        }
    }
    .navbar-brand {
        align-items: center;
        padding-top: 5px;
        padding-bottom: 0px;
        .readiness {
            flex-direction: row;
            font-size: 14px;
            margin-bottom: 0px;
            color: #5b5353;
            border-color: #5b5353;
            transition: all 0.5s ease;
            border-left: none;
            margin-left: 0;
            padding-left: 10px;
            span {
                margin-right: 5px;
            }
            // @include mobile {
            //     border-left: 1px solid #5b5353;
            // }
            // @include tablet {
            //     border-left: 1px solid #5b5353;
            // }
        }
        @include mobile {
            padding-top: 0;
            .readiness {
                padding-left: 15px;
            }
        }
        @include tablet {
            padding-top: 0;
            .readiness {
                padding-left: 15px;
            }
        }

    }
    .navbar-toggler {
            @include tablet {
                filter: brightness(0.4);
            }
        }
    .nav-menu {
        .nav-link {
            color: #5b5353;
            @include large {
                font-weight: 300;
                padding-top: 0px;
                padding-bottom: 0px;
            }
            @include desktop {
                font-weight: 500;
            }
        }
        .nav-item {
            &.black {
                display: block;
            }
            &.white {
                display: none;
            }
        }
    }
}
.logo-wrapper {
    position: relative;
    display: flex;
    
    .logo-planet {
        // transform-origin: top left;
        margin-right: 5px;
        transition: all 0.5s ease;
    } 
    .logo-text {
        // top: 35px;
        margin-top: 50px;
        opacity: 1;
        transition: all 0.5s ease;
    }
    @include mobile {
        display: block;
        .logo-planet {
            width: 36px;
            height: 36px;
            transition: all 0.5s ease;
        }
        .logo-text {
            position: absolute;
            opacity: 0;
            margin-top: 0px;
            transition: all 0.5s ease;
            // position: absolute;
        }
    }
    @include tablet {
        display: block;
        .logo-planet {
            width: 36px;
            height: 36px;
            transition: all 0.5s ease;
        }
        .logo-text {
            position: absolute;
            opacity: 0;
            margin-top: 0px;
            transition: all 0.5s ease;
            // position: absolute;
        }
    }
} 
.navbar-brand {
    display: flex;
    align-items: flex-end;
    .readiness {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
        font-family: 'MuseoSlab';
        font-weight: 300;
        line-height: 1.19;
        padding-left: 15px;
        border-left: 1px solid white;
        margin-left: 20px;
        margin-bottom: -1px;
        transition: all 0.5s ease;
        span {
            &:last-child {
                line-height: 1;
            }
        }
    }
    @include mobile {
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
        .readiness {
            flex-direction: row;
            font-size: 14px;
            margin-left: 0;
            transition: all 0.5s ease;
            color: #5b5353;
            border-color: #5b5353;
            span {
                margin-right: 5px;
            }
        }
    }
    @include tablet {
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
        .readiness {
            flex-direction: row;
            font-size: 14px;
            margin-left: 0;
            transition: all 0.5s ease;
            color: #5b5353;
            border-color: #5b5353;
            span {
                margin-right: 5px;
            }
        }
    }
}
.nav-menu {
    justify-content: flex-end;
    .navbar-nav {
        align-items: center;
    }
    .nav-link {
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        padding-left: 16px;
        padding-right: 16px;
        @include mobile {
            font-weight: 300;
            color: #5b5353;
        }
        @include tablet {
            font-weight: 300;
            color: #5b5353;
        }
    }
    .nav-item {
        &.black {
            display: none;
        }
        @include mobile {
            &.black {
                display: block;
            }
            &.white {
                display: none;
            }
        }
        @include tablet {
            &.black {
                display: block;
            }
            &.white {
                display: none;
            }
        }
    }
}
.search {
    display: none;
    form {
        display: flex;
    }
}
.stories-single {
    .logo-wrapper {
        .logo-text {
            filter: brightness(0.2);
        }
    }
    .navbar-brand {
        color: #5b5353;
        @include mobile {
            color: #fff;
        }
        .readiness {
            border-left: 1px solid #5b5353;
        }
        // .readiness {
        //     border-left: 1px solid #5b5353;
        //     @include mobile {
        //         border-left: 1px solid #fff;
        //     }
        // }
    }
    .nav-menu {
        .nav-link {
            color: #5b5353;
        }
        .nav-item {
            &.black {
                display: block;
            }
            &.white {
                display: none;
            }
        }
    }
    .light {
    //     .logo-wrapper {
    //         .logo-text {
    //             filter: brightness(1);
    //         }
    //     }
    //     .navbar-brand {
    //         color: #fff;
    //     }
    //     .nav-menu {
    //         .nav-link {
    //             color: #fff;
    //         }
    //         .nav-item {
    //             &.black {
    //                 display: none;
    //             }
    //             &.white {
    //                 display: block;
    //             }
    //         }
    //     }
        .readiness {
            border-left: none;
        }
    }
}
.toggle-sm {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 11;
    padding: 18px 24px 16px;
    border-radius: 90px;
    box-shadow: 0 12px 23px 0 rgba(34, 146, 92, 0.25);
    background-color: #19ac98;
    background-image: linear-gradient(to right, #17c6ae 3%, #6ab2e6 89%);
    color: #ffffff;
    font-weight: bold;
    span {
        margin-left: 10px;
    }
}
</style>